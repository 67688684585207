import { render, staticRenderFns } from "./serve.vue?vue&type=template&id=254b1a5e&scoped=true&"
import script from "./serve.vue?vue&type=script&lang=js&"
export * from "./serve.vue?vue&type=script&lang=js&"
import style0 from "./serve.vue?vue&type=style&index=0&lang=css&"
import style1 from "./serve.vue?vue&type=style&index=1&id=254b1a5e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254b1a5e",
  null
  
)

export default component.exports